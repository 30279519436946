import React, { Component } from 'react';
import './App.css';
import logo from './logo.png';
import DrawableCanvas from 'react-drawable-canvas';
class App extends Component {
  render() {
    const style = {
      brushColor: "#ECF0F1",
      lineWidth: 2,
      canvasStyle: {
        backgroundColor: 'FFFFFF'
      },
      clear: false
    }
    
    return (
          <div className="draw-container">
          <DrawableCanvas  {...style} />
  
          </div>
      
    );
  }
}

export default App;
